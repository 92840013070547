import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="set-last-breadcrumb"
export default class extends Controller {
  static targets = ["name"]
  connect() {
    if (this.hasNameTarget) {
      const lastBreadcrumb = document.querySelector('.link__forbidden');
      if (lastBreadcrumb) {
        lastBreadcrumb.textContent = this.nameTarget.textContent;
      }
    }
  }
}
